<template>
  <div class="bg">
    <div class="cen">
      <div class="text-center">
        <img
          src="/logo/lgo.png"
          alt="logo-lotto-vip"
          style="margin-top: 200px;"
        >
      <!-- button router-link to="/Lottery" -->
      </div>

      <div class="text-center mt-4">
        <button
          class="btn btn-warning btn-lg"
          style="background: linear-gradient(184.78deg, #EFD47A -5.17%, #C68C31 49.71%, #FFF4BD 96.14%); color: #000 !important; font-weight: 700;"
          @click="LinkToLine()"
        >
          ซื้อหวย คลิก
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      invcode: this.$route.params.invcode,
    }
  },
  mounted() {
    if (this.$route.params.invcode) {
      this.invcode = this.$route.params.invcode
    }
  },
  methods: {
    LinkToLine() {
      window.location.href = 'https://line.me/R/ti/p/@670wdtom'
    },
  },
}
</script>

<style scoped>
.bg {
  background-color: #fff;
  height: 100vh;
}
.bg {
  background-image: url(/logo/bg3.svg),linear-gradient(154.06deg, #b70009 0%, #500009 100%) ;
  background-size: 50px;
  background-repeat: repeat;
  /* background-color: rgba(26, 17, 8, 0.459); */
}
img {
    width: auto;
    height: 150px;
}
</style>
